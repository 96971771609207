@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  --brand: #f69200;
  --darkOrange: #ff7004;
  --gray: #f2f2f2;
  --white: #ffffff;
  --black: #0c0c0c;
  --blue: #0080ff;
  --red: #ff0000;
  --purple: #cf00d3;
  --green: #00e500;
  --darkGreen: #00e400;
  --magenta: #ff0089;
  --darkMagenta: #b50061;
  --pink: #ff5abd;
  --blackText: rgb(21, 0, 103);
  --blurple: #413790;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f0;
  overflow-x: hidden;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}