.document {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  padding: 30px;
  margin: auto;
  margin-top: 100px;
  max-width: 600px;

  align-self: center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.small {
  margin-top: 0px;
  padding: 10px;
}

.document img {
  pointer-events: none;
}

.document > header {
  font-weight: 700;
  text-align: center;
}

.documentInner {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.documentColumn {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

h2 {
  font-size: 17px;
  font-weight: 700;
  align-self: center;
}

h3 {
  font-weight: 700;
  font-size: 16px;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.numberList {
  list-style: decimal;
}

.arrowList {
  list-style: disc;
}

.documentTableWrap {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.documentTableWrapColumn {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.documentDate {
  align-self: flex-end;
  padding-right: 50px;
}

.document img {
  width: 100%;
}
