* {
  font-family: "Inter";
}

.form_information {
  color: var(--white);
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .form_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    align-self: stretch;

    h1 {
      font-size: 30px;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  --form-text-color: #3f3e3e;
  max-width: 626px;
  width: calc(100svw - 36px);
  margin-bottom: 50px;

  /* ONLY PC */
  align-self: center;
}

.form_paginationInfo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  place-items: center;
  h2 {
    font-weight: 500;
  }
}

.form_paginationDots {
  display: flex;
  flex-direction: row;
  gap: 10px;
  place-items: center;

  height: 24px;

  .paginationDot {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 12px;
    box-sizing: border-box;

    transition-duration: 0.3s;

    &[data-currentpage="true"] {
      width: 24px;
      height: 24px;
      background-color: var(--white);
      border: 3px solid rgba(0, 0, 0, 0.1);
    }
    &[data-completed="true"] {
      background-color: #083d54;
    }
  }
}

.form_message-green {
  --size: 46px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  justify-content: center;
  align-items: center;

  & > svg {
    fill: #02d100;
    width: calc(var(--size) * 1.1);
    height: var(--size);
    padding: none;
    overflow: visible;
  }

  & > p {
    text-align: left;
    font-size: 19px;
    font-weight: 500;
    color: var(--form-text-color);
  }
}

.form_message-red {
  --size: 46px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  justify-content: center;
  align-items: center;

  & > svg {
    fill: #f30000;
    width: var(--size);
    height: var(--size);
    overflow: visible;
  }

  & > p {
    text-align: left;
    font-size: 19px;
    font-weight: 500;
    color: var(--form-text-color);
  }
}

main[id="studentapplication"] {
  background-image: url(../../assets/form/bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center 80%;

  min-width: 100svw;
  min-height: 92svh;
  overflow-x: hidden;

  display: grid;
  /* padding-top: 200px; */
  justify-content: center;
}

.languageSelector {
  display: flex;
  flex-direction: row;
  gap: 0;

  width: 80px;
  height: 30px;

  border-radius: 15px;

  background-color: var(--white);

  box-sizing: border-box;
  padding: 3px;

  .languageRadioSelect {
    box-sizing: border-box;
    position: relative;

    font-weight: 800;

    height: 100%;
    width: fill-available;
    height: fill-available;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    width: -moz-available;

    color: var(--form-text-color);

    border-radius: 15px;
    font-size: 10px;

    display: grid;
    place-items: center;

    cursor: pointer;

    label {
      cursor: pointer;
    }
    label:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }

    input:checked[id="tr"] ~ &[for="tr"] {
      background-color: #eceff0;
    }
    input:checked[id="en"] ~ &[for="en"] {
      background-color: #eceff0;
    }
  }
}

.languageSelector input[type="radio"] {
  display: none;
}

.form_fragment {
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;

  padding: 25px;
  background-color: var(--white);
  border-radius: 20px;

  gap: 16px;
  display: flex;
  flex-direction: column;

  overflow: hidden;
}

.form_fragment_pagination {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.form_pagination_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 14px;

  &:not(:has(button:first-child:last-child)) {
    justify-content: space-between;
  }

  .button {
    box-sizing: border-box;
    padding: 12px 36px;
    font-size: 18px;
    border: none;
    border-radius: 20px;

    &.blue {
      background-color: var(--blue);
      color: var(--white);
    }
    &.white {
      background-color: var(--white);
      color: var(--form-text-color);
    }
  }
}

.contactdialog {
  position: fixed;

  transform: translate(-50%, -50%);

  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 20px;
  border-radius: 20px;

  box-sizing: border-box;

  width: calc(100svw - 40px);
  max-width: 400px;

  background: var(--white);

  & > .contactdialog_fragment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    & > span {
      background-color: rgba(54, 54, 219, 0.1);
      padding: 4px 10px;
      border-radius: 5px;

      color: var(--blue) !important;
    }
  }

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background: rgba(128, 128, 128, 0.2);
  }

  .modalAnchor {
    text-decoration: none;
    color: var(--blue);
    font-weight: 500;
  }

  button {
    width: 100%;
    height: 36px;
    border: none;
    border-radius: 10px;
    color: var(--white);
    background-color: var(--blue);

    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: var(--white);

    z-index: -1;
  }

  &::before {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 10, 56, 0.2);

    z-index: -2;
  }

  &[data-open="false"] {
    display: none;
  }

  z-index: 1000;
}

@media screen and (max-width: 726px) {
  form {
    align-self: flex-start;
  }

  .form_information .form_title h1 {
    font-size: 22px;
  }

  .form_paginationDots {
    gap: 7px;

    .paginationDot {
      width: 8px;
      height: 8px;

      &[data-currentpage="true"] {
        width: 16px;
        height: 16px;
        border-width: 2px;
      }
    }
  }

  main[id="studentapplication"] {
    background-position: 90% 50%;
    padding-top: 50px;

    min-height: 100svh;
  }

  .form_fragment {
    padding: 15px;
  }

  .form_message-red {
    --size: 64px;

    flex-direction: column;
    gap: 20px;

    padding-block: 10px;

    & > p {
      font-size: 18px;
      text-align: center;
    }
  }
  .form_message-green {
    --size: 64px;

    flex-direction: column;
    gap: 20px;

    padding-block: 10px;

    & > p {
      font-size: 18px;
      text-align: center;
    }
  }

  .form_pagination_buttons {
    flex-direction: column-reverse;
  }

  .form_pagination_buttons > .button {
    padding: 12px 10px;
    font-size: 16px;
    &.blue {
      border: 2px solid rgba(255, 255, 255, 0.2) !important;
    }
  }
}
