.forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: center;
  margin: auto;
  margin-top: 100px;
  width: 570px;
}

.form {
  background: var(--white);
  border-radius: 10px;
  min-width: 100%;
  padding: 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formTitle {
  margin: 0;
  width: fit-content;
  font-size: 25px;
  font-weight: bold;
  color: var(--blackText);
  align-items: flex-start !important;
}

.formId {
  color: gray;
  width: 300px;
  text-align: end;
}

.formValuesGrid {
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  gap: 10px;
  height: 100%;
}

.formValues {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formValueWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.formValueWrapper h3 {
  font-size: 13px;
  color: gray;
}

.formValue {
  padding-inline: 20px;
  padding-block: 10px;
  background-color: var(--gray);
  border-radius: 5px;
  padding-right: auto;
  display: flex;
  justify-content: space-between;
}

.formValue span {
  opacity: 0.8;
  font-size: 14px;
  color: var(--black);
  fill: rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.applicatorFormValues {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--blue);
  border-radius: 10px;
  padding: 20px;
}

.applicatorFormValues .formValue {
  background: var(--white);
}

.applicatorFormValues h3 {
  color: var(--white);
  opacity: 0.8;
}

.applicatorFormValues h2 {
  color: var(--white);
}

.quotaWrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.quotaButton {
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--green);
  background: #51eb2424;
  font-size: 16px;
  font-weight: bold;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.quotaButton.closed {
  background-color: #ff000011;
  color: var(--red);
}

.quotaButton.processing {
  background-color: #f6940017;
  color: var(--brand);
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.deleteButton {
  border: 3px solid var(--red);
  border-radius: 10px;
  padding: 15px;
  background: #ff000012;
  color: var(--red);
  font-weight: bold;
  font-size: 13px;
  outline: none;
  cursor: pointer;
}

.deleteButton.processing {
  border-color: var(--brand);
  background-color: #f6940017;
  color: var(--brand);
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.deleteButton.confirmed {
  border-color: var(--green);
  background-color: #52eb2410;
  color: var(--green);
}

.deleteButton.errored {
  border-color: var(--purple);
  background-color: #8c06b110;
  color: var(--purple);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.formButtonWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pdfOut {
  background: var(--white);
  border-radius: 10px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pdfIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  gap: 10px;
  border: none;
  background: var(--blue);
  color: var(--white);
  width: 100%;
  cursor: pointer;
}

.pdfIn svg {
  fill: var(--white);
  width: 15px;
}

.xlsxIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  gap: 10px;
  border: none;
  background: var(--green);
  color: var(--white);
  width: 100%;
  cursor: pointer;
}

.xlsxIn svg {
  fill: var(--white);
  width: 15px;
}

.downloadButtonsWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.modalOpenButtonXlsx {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition-duration: 300ms;
  background: var(--green);
  color: var(--white);
}

.modal {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 600px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  background: var(--white);
  padding: 30px;
  border-radius: 10px;

  z-index: 11;
}

.inactive {
  display: none;
}

.selectForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectInput {
  display: none;
}

.selectLabel {
  padding: 15px 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;

  cursor: pointer;
}

.selectInput:checked ~ .selectLabel {
  border: 2px solid var(--green);
  color: var(--green);
}

.modal > button {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;

  background: var(--green);
  color: var(--white);
  border-radius: 4px;

  cursor: pointer;

  opacity: 0.5;
}

.modal > button.active {
  opacity: 1;
}

.disabled {
  cursor: not-allowed !important;
}
