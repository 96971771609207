.input_ask input[type="radio"] {
  display: none;
}

.input_ask {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;

  p {
    font-size: 18px;
    color: #3f3e3e;
  }
}

.input_ask[data-error="true"] p {
  --error-red: rgb(207, 12, 12);
  color: var(--error-red) !important;

  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--red);
    left: -30px;
    transform: translateY(-50%);
    top: 50%;
  }
}

.ask_radios {
  display: flex;
  flex-direction: row;
  gap: 6px;

  width: 140px;
  height: 36px;

  .askRadioSelect {
    box-sizing: border-box;
    position: relative;

    height: 100%;
    width: fill-available;
    height: fill-available;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    width: -moz-available;

    border: 2px solid var(--blue);
    color: var(--blue);

    border-radius: 5px;
    font-size: 14px;

    display: grid;
    place-items: center;

    cursor: pointer;
    label {
      cursor: pointer;
    }
    label:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }

    input:checked[value="y"] ~ &[data-forvalue="y"] {
      background-color: var(--blue);
      color: white;
    }
    input:checked[value="n"] ~ &[data-forvalue="n"] {
      background-color: var(--blue);
      color: white;
    }
  }
}

@media screen and (max-width: 726px) {
  .input_ask {
    p {
      font-size: 16px;
      color: #3f3e3e;
    }
  }

  .ask_radios {
    height: 40px;
    width: 160px;
  }

  .input_ask[data-error="true"] p {
    &:before {
      left: -20px;
    }
  }
}
