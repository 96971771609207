.formWrap {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  padding-top: 40px;
  overflow: hidden;
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
}

.formTitle * {
  margin: 0;
}

form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.formSubmitButton {
  padding: 15px 0 15px 0;
  background-color: var(--brand);
  border-radius: 5px;
  border: none;
  color: var(--white);
  font-size: 15px;
  cursor: pointer;
}

.warn {
  padding: 20px 30px;
  border-radius: 10px;
  border: 2px solid var(--brand);
  background: #fff3e2;
}

.quotaModal {
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 300px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 10px;
  padding: 20px;

  z-index: 11;
}

.inactive {
  display: none;
}

.modalBg {
  z-index: 10;
  background: var(--black);
  width: 100vw;
  height: 100vh;
  opacity: 0.8;
  position: fixed;
  top: 0;
  backdrop-filter: blur(20px);
}

.modalQuotaWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.modalTitle {
  font-size: 20px;
  color: var(--blackText);
  font-weight: bold;
}

.modalCloseButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.modalCloseButton svg {
  width: 18px;
  height: 18px;
}

.modalHeaderWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.closedButton {
  padding-block: 5px;
  background: var(--brand);
  color: var(--white);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 140px;
}

.openButton {
  padding-block: 5px;
  background: var(--green);
  color: var(--white);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 140px;
}

.quotaOpenButton {
  padding-block: 12px;
  background: var(--white);
  color: var(--blue);
  border: none;
  border: 2px solid var(--blue);
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  transition-duration: 0.1s;
}

.formOuterWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  margin-top: 60px;
  max-width: 600px;
  align-self: center;
}

@media screen and (max-width: 520px) {
  .formOuterWrap {
    margin-top: 20px;
    width: calc(100vw - 40px);
  }

  .formWrap {
    padding-inline: 15px;
    padding-block: 30px;
    box-sizing: border-box;
  }
}