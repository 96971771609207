.wrap {
    --box-size: 200px;
    --gap: 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(5 * (var(--box-size) + var(--gap)));
    gap: var(--gap);
    padding: var(--gap);
    justify-content: center;

    margin: 50px auto;
}

.box {
    width: var(--box-size);
    height: var(--box-size);

    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background-color: #ffffff;

    text-decoration: none;

    padding: 20px;
    box-sizing: border-box;
}

.box>h1 {
    font-size: 16.5px;
    color: #000;
    font-weight: 500;
    text-align: center;
}

.box>img {
    --size: 80px;
    width: var(--size);
    height: var(--size);
}

.box:hover {
    border: 2px solid var(--red);
}