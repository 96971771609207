.input_select {
  --background: #eceff0;
  --text-color: #989baa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;

  position: relative;

  gap: 10px;

  p {
    font-size: 18px;
    color: var(--form-text-color);
  }
}

.input_select[data-error="true"] p {
  --error-red: rgb(207, 12, 12);
  color: var(--error-red) !important;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--red);
    left: -30px;
    transform: translateY(-50%);
    top: 50%;
  }
}

/* RESET STYLES */
.input_select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  &::-ms-expand {
    display: none;
  }

  width: 100%;
  height: 100%;

  padding-inline: 13px;
  color: var(--form-text-color);
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;

  padding-right: 18px;
  min-width: 76px;
  max-width: 120px;
  height: 36px;

  font-size: 14px;

  border: none;
  border-radius: 5px;

  background-color: var(--background);

  cursor: pointer;
}

.input_select select,
.select:after {
  grid-area: select;
}

.select:after {
  justify-self: end;
}

.select::after {
  content: "";
  border: solid var(--text-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition-duration: 0.2s;
  margin-right: -5px;
}

.select:focus-within::after {
  transform: rotate(-135deg);
}

@media screen and (max-width: 726px) {
  .input_select {
    p {
      font-size: 16px;
      color: #3f3e3e;
    }
  }

  .input_select select {
    padding-inline: 13px;
    height: 30px;
  }

  .input_select[data-error="true"] p {
    &::before {
      left: -20px;
    }
  }
}
