/* REHBERLIK */
.subPages_hizmetlerimiz-rehberlik_wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.subPages_hizmetlerimiz-rehberlik_card {
  width: 700px;
  border-radius: 10px;
  color: var(--white);
  background-size: cover !important;
  background-position: 50% 100% !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 40px;
  box-sizing: border-box;
}

.subPages_hizmetlerimiz-rehberlik_card > h1 {
  font-size: 20px;
  font-weight: 550;
}
.subPages_hizmetlerimiz-rehberlik_card > p {
  font-size: 15px;
  width: 500px;
}

.subPages_hizmetlerimiz-rehberlik_card:nth-child(even) {
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}

/* AVANTAJLAR */
.subPages_hizmetlerimiz-avantajlar_wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 50px;
  width: 850px;
}

.subPages_hizmetlerimiz-avantajlar_wrap > img {
  width: 250px;
  height: 250px;
}

.subPages_hizmetlerimiz-rehberlik_card:nth-child(1) {
  background: url(../../../assets/rehberlik/puzzlee_card.png),
    linear-gradient(0deg, var(--blue), var(--blue));
}
.subPages_hizmetlerimiz-rehberlik_card:nth-child(2) {
  background: url(../../../assets/rehberlik/chart_card.png),
    linear-gradient(0deg, var(--brand), var(--brand));
}
.subPages_hizmetlerimiz-rehberlik_card:nth-child(3) {
  background: url(../../../assets/rehberlik/magnify_card.png),
    linear-gradient(0deg, var(--magenta), var(--magenta));
}
.subPages_hizmetlerimiz-rehberlik_card:nth-child(4) {
  background: url(../../../assets/rehberlik/comment_card.png),
    linear-gradient(0deg, var(--darkGreen), var(--darkGreen));
}
.subPages_hizmetlerimiz-rehberlik_card:nth-child(5) {
  background: url(../../../assets/rehberlik/chain_card.png),
    linear-gradient(0deg, var(--purple), var(--purple));
}

/* DEFAULTCARD */
.subPages_hizmetlerimiz-defaultCard {
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 700px;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/342.png);
  color: var(--white);
  box-sizing: border-box;
  background-size: cover !important;
}

.subPages_hizmetlerimiz-defaultCard.DJI0061 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/DJI_0061.jpg);
}
.subPages_hizmetlerimiz-defaultCard.IMG_4466 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/IMG_4466.jpg);
}
.subPages_hizmetlerimiz-defaultCard.TKM_7145 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/TKM_7145.jpg);
}

.subPages_hizmetlerimiz-defaultCard_firstColumn {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subPages_hizmetlerimiz-defaultCard_firstColumn > .img {
  width: 300px;
  height: 250px;
  border-radius: 10px;
  background-size: cover !important;
  background-position: center center !important;
}

.subPages_hizmetlerimiz-defaultCard_firstColumn > .iframe {
  overflow: hidden;
  border-radius: 5px;
  height: 395px;
  position: relative;
}

.subPages_hizmetlerimiz-defaultCard_firstColumn
  > .iframe
  > div:not(.iframe_placeholder) {
  width: 279px;
  height: 452px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
}

.subPages_hizmetlerimiz-defaultCard_firstColumn > .iframe > div > iframe {
  margin-left: 0;
  height: 100%;
  position: absolute;
  top: -10px;
  transform: scale(1.02);
}

.iframe > .iframe_placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.iframe {
  animation: 3s pulse infinite;
}
@keyframes pulse {
  0% {
      background: gray;
  }
  50% {
      background: lightgray;
  }
  100% {
      background: gray;
  }
}

.subPages_hizmetlerimiz-defaultCard_secondColumn {
  font-size: 14px;
}

.subPages_hizmetlerimiz-defaultCard_firstColumn > .iframe_warning {
  display: none;
}

@media screen and (max-width: 900px) {
  .subPages_hizmetlerimiz-avantajlar_wrap {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 750px) {
  .subPages_hizmetlerimiz-rehberlik_card {
    width: 90vw;
    justify-content: center !important;
    align-items: center !important;
    text-align: left !important;
  }
  .subPages_hizmetlerimiz-rehberlik_card > h1 {
    width: 100%;
    align-self: left;
  }
  .subPages_hizmetlerimiz-rehberlik_card > p {
    width: 100%;
    align-self: left;
  }
  .subPages_hizmetlerimiz-defaultCard {
    flex-direction: column-reverse;
    width: 330px;
    align-items: center;
  }
  .subPages_hizmetlerimiz-defaultCard_firstColumn {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .subPages_hizmetlerimiz-defaultCard_firstColumn > .iframe_warning {
    width: 95%;
    display: flex;
    text-align: center;
    font-size: 12px;
    color: var(--brand);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
    padding-block: 10px;
  }
}
