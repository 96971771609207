:root {
  --mobileDifferanceLinear: linear-gradient(0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%);
  --desktopDifferanceLinear: linear-gradient(90deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%);
}

.homeContentColumn {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

.homeContentBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 40px 0;
  width: 100%;
  overflow: hidden;
}

.white:is(.homeContentBox) {
  background-color: var(--white);
}

.pattern:is(.homeContentBox) {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)),
    url(../../assets/wowlStudiosPattern.png);
  background-size: cover;
}

.blue:is(.homeContentBox) {
  background-color: var(--blue);
}

.brand:is(.homeContentBox) {
  background-color: var(--brand);
}

.pink:is(.homeContentBox) {
  background-color: var(--pink);
}

.magenta:is(.homeContentBox) {
  background-color: var(--magenta);
}

.purple:is(.homeContentBox) {
  background-color: var(--purple);
}

.homeContentBoxTitle {
  text-transform: uppercase;
  font-size: 17px;
}

.capitalize {
  text-transform: capitalize !important;
}

.brand:is(.homeContentBoxTitle) {
  color: var(--brand);
}

.white:is(.homeContentBoxTitle) {
  color: var(--white);
}

.blue:is(.homeContentBoxTitle) {
  color: var(--blue);
}

.purple:is(.homeContentBoxTitle) {
  color: var(--purple);
}

.differanceWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.differanceBox {
  width: 750px;
  height: 65px;
  border-radius: 15px;
  color: var(--white);

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;

  padding: 20px;
}

.differanceBox>p {
  max-width: 500px;
}

.differanceBox>h3 {
  font-size: 18px;
}

.differanceBox:nth-child(1) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/537.webp);
  background-position: center 60%;
  background-size: 790px;
}

.differanceBox:nth-child(2) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/464.webp);
  background-position: center 50%;
  background-size: 790px;
}

.differanceBox:nth-child(3) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/bebekler.jpg);
  background-position: center 50%;
  background-size: 790px;
}

.differanceBox:nth-child(4) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/371.webp);
  background-position: center 60%;
  background-size: 790px;
}

.differanceBox:nth-child(5) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/TKM_3909.webp);
  background-position: center 47%;
  background-size: 790px;
}

.differanceBox:nth-child(6) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/TKM_7367.webp);
  background-position: center 50%;
  background-size: 790px;
}

.differanceBox:nth-child(7) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/571.webp);
  background-position: center 50%;
  background-size: 790px;
}

.differanceBox:nth-child(8) {
  background: var(--desktopDifferanceLinear),
    url(../../assets/compressed/584.webp);
  background-position: center 60%;
  background-size: 790px;
}

/* GALLERY */
.galleryContentWrap {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
}

.galleryDisplayImage {
  width: 500px;
  height: 335px;
  border-radius: 20px;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  padding: 30px 40px;
  box-sizing: border-box;
  color: var(--white);
  background-size: cover;
}

.galleryImageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  width: 500px;
  height: 275px;
}

.galleryImageGrid>button {
  position: relative;
  border-radius: 10px;
  background-size: cover;
  box-sizing: border-box;
  border: none;

  cursor: pointer;
}

.onDisplay:is(.galleryImageGrid > button) {
  border: 4px solid var(--white);
  transition-duration: 0.1s;
}

.onDisplay:is(.galleryImageGrid > button):before {
  content: " ";
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 4px solid var(--blue);
  border-radius: 6px;
  transition-duration: 0.1s;
}

.galleryImageGrid>div:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}

.galleryImageGrid>div:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.galleryImageGrid>div:nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
}

.galleryImageGrid>div:nth-child(4) {
  grid-area: 1 / 4 / 2 / 5;
}

.galleryImageGrid>div:nth-child(5) {
  grid-area: 2 / 1 / 3 / 2;
}

.galleryImageGrid>div:nth-child(6) {
  grid-area: 2 / 2 / 3 / 3;
}

.galleryImageGrid>div:nth-child(7) {
  grid-area: 2 / 3 / 3 / 4;
}

.galleryImageGrid>div:nth-child(8) {
  grid-area: 2 / 4 / 3 / 5;
}

.galleryImageGrid>div:nth-child(9) {
  grid-area: 3 / 1 / 4 / 2;
}

.galleryImageGrid>div:nth-child(10) {
  grid-area: 3 / 2 / 4 / 3;
}

.galleryImageGrid>div:nth-child(11) {
  grid-area: 3 / 3 / 4 / 4;
}

.galleryImageGrid>div:nth-child(12) {
  grid-area: 3 / 4 / 4 / 5;
}

.galleryImageGridAndButtonWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 335px;
}

.galleryMobileSlider {
  width: 430px;
  overflow: hidden;
  position: relative;
}

.galleryMobileSlides {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 81px;

  position: relative;
  left: 150px;

  transition-duration: 0.3s;
}

.box-4324:is(.prev),
.box-4324:is(.next) {
  transform: scale(0.8);
}

.box-4324:is(.onDisplay) {
  border: 4px solid var(--white);
  transition-duration: 0.1s;

  box-sizing: border-box;
}

.box-4324:is(.onDisplay):before {
  content: " ";
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 4px solid var(--blue);
  border-radius: 6px;
  transition-duration: 0.1s;
}

.box-4324 {
  width: 130px;
  height: 73px;
  background-size: cover;
  border-radius: 10px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  position: relative;
}

#gallery-slidecontrol-left,
#gallery-slidecontrol-right {
  color: var(--blue);
  background: var(--white);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  position: absolute;
  transform: translateY(-50%);

  cursor: pointer;
  z-index: 2;
}

#gallery-slidecontrol-left>svg,
#gallery-slidecontrol-right>svg {
  width: 20px;
  height: 20px;
  stroke: var(--blue);
}

#gallery-slidecontrol-left {
  padding-right: 1px;
  top: 50%;
  left: 0;
}

#gallery-slidecontrol-right {
  padding-left: 5px;
  top: 50%;
  right: 0;
}

/* REVIEWS */
.reviewsSlider {
  position: relative;
  width: 800px;
  height: 250px;
}

.reviewsSlides {
  display: flex;
  flex-direction: row;
  gap: 100px;
  width: fit-content;
  position: absolute;

  transition-duration: 0.7s;
}

.reviewCard:is(.prev),
.reviewCard:is(.next) {
  transform: scale(0.8);
}

#reviews-slidecontrol-left,
#reviews-slidecontrol-right {
  color: var(--brand);
  background: var(--white);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  position: absolute;
  transform: translateY(-50%);

  cursor: pointer;
  z-index: 2;

  border: 5px solid var(--gray);
}

#reviews-slidecontrol-left>svg,
#reviews-slidecontrol-right>svg {
  width: 20px;
  height: 20px;
  stroke: var(--brand);
}

#reviews-slidecontrol-left {
  padding-right: 1px;
  top: 50%;
  left: 0;
}

#reviews-slidecontrol-right {
  padding-left: 5px;
  top: 50%;
  right: 0;
}

/* review card */

.reviewCard {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 250px;
  justify-content: space-between;
  align-items: flex-start;
  background: var(--white);
  border-radius: 15px;
  padding: 25px 30px;
  box-sizing: border-box;
  transition-duration: 0.7s;
}

.reviewCardInnerWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}

.reviewAuthorDetails {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: left;
  align-items: center;
}

.reviewsAuthorTextWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}

.reviewAuthorDetails>img {
  height: 54px;
  width: 54px;
  border-radius: 100%;

  border: 3px solid var(--gray);
  box-sizing: border-box;
}

.reviewsAuthorTextWrap>h3 {
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
}

.reviewsAuthorTextWrap>span {
  color: var(--brand);
}

.reviewDate {
  align-self: flex-end;
  justify-self: flex-end;

  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: 600;

  opacity: 0.5;
}

.reviewDate>p {
  padding-top: 1.5px;
}

.reviewDate>svg {
  width: 20px;
  height: 20px;
}

.avatarGroup {
  display: inline-flex;
  flex-direction: row-reverse;
}

.advertSmallAvatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 5px solid var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.advertSmallAvatar:not(:last-child) {
  margin-left: -30px;
}

.advertSmallAvatar img {
  width: 100%;
  height: 100%;
  border: 4px solid var(--brand);
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
}

.reviewAdvert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  gap: 15px;
}

.reviewAdvert>a {
  color: var(--white);
  background: var(--brand);
  text-decoration: none;
  border-radius: 20px;
  padding-block: 7px;
  padding-inline: 20px;
}

/* TIMEMAP */
.timeMapWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 15px 15px;
  grid-template-areas:
    "oyunoynadik hayvanbesledik"
    "dramayaptik ingilizcekonustuk"
    "yuzduk yuzduk";
  width: 650px;
}

.timeWrapBox {
  display: flex;
  flex-direction: column;
  gap: 0;
  color: var(--white);
  justify-content: flex-end;
  align-items: flex-start;
  height: 100px;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
}

.oyunoynadik {
  background: linear-gradient(87.96deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 70%),
    url(../../assets/compressed/476.webp);
  background-size: cover;
  background-position: center center;
  grid-area: oyunoynadik;
}

.hayvanbesledik {
  background: linear-gradient(87.96deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 70%),
    url(../../assets/compressed/14.webp);
  background-size: 115%;
  background-position: 0% 100%;
  grid-area: hayvanbesledik;
}

.dramayaptik {
  background: linear-gradient(87.96deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 70%),
    url(../../assets/compressed/45.webp);
  background-size: cover;
  background-position: center center;
  grid-area: dramayaptik;
}

.ingilizcekonustuk {
  background: linear-gradient(87.96deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 70%),
    url(../../assets/compressed/42.webp);
  background-size: 110%;
  background-position: 0% 20%;
  grid-area: ingilizcekonustuk;
}

.yuzduk {
  background: linear-gradient(87.96deg,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 70%),
    url(../../assets/compressed/464.webp);
  background-size: cover;
  background-position: center center;
  grid-area: yuzduk;
}

/* GOOGLE MAPS */
.googleMap {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}

/* COMMUNUCATION */
.commWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 980px;
}

.commBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  width: 100%;

  padding: 10px 50px;

  box-sizing: border-box;
}

.commMethodWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.commMethodWrap>p {
  color: var(--black);
  font-size: 15px;
  max-width: 200px;
}

.commMethodWrap>div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: var(--blue);
}

.commMethodWrap>div>svg {
  width: 25px;
  height: 25px;
}

.commButton {
  width: 100%;
  padding: 15px;
  color: var(--blue);
  background: url(../../assets/compressed/kampusPattern.webp),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-size: cover;
  background-position: center center;
  border: 4px solid var(--blue);
  border-radius: 10px;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.commButton>svg {
  width: 18px;
  height: 18px;
}

/* CREDIT */
.creditArea {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 500px;
}

.credit {
  text-decoration: none;
}

.credit>h3 {
  color: var(--black);
}

.credit:nth-child(1)>span {
  color: #04cfa4;
}

.credit:nth-child(2)>span {
  color: #29acff;
}

.credit:nth-child(3)>span {
  color: #7d53dd;
}



@media screen and (max-width: 1115px) {
  .galleryContentWrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

@media screen and (max-width: 1020px) {
  .commWrap {
    width: 87vw;
  }
}

@media screen and (max-width: 920px) {
  .commBox {
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  .commMethodWrap>p {
    max-width: 60vw;
  }
}

@media screen and (max-width: 865px) {
  .homeContentBox {
    gap: 20px;
    padding: 30px 0;
  }

  .differanceBox {
    box-sizing: border-box;
    width: 100%;
    height: 350px;
    justify-content: flex-end;
    gap: 5px;
    background-size: cover !important;
    border-radius: 12px;
    padding-bottom: 25px;
  }

  .differanceWrap {
    box-sizing: border-box;
    padding: 20px;
    gap: 15px;
  }

  .differanceBox:nth-child(1) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/537.webp);
    background-position: center 60%;
  }

  .differanceBox:nth-child(2) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/464.webp);
    background-position: center 50%;
  }

  .differanceBox:nth-child(3) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/bebekler.jpg);
    background-position: center 60%;
  }

  .differanceBox:nth-child(4) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/371.webp);
    background-position: center 60%;
  }

  .differanceBox:nth-child(5) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/TKM_3909.webp);
    background-position: center 47%;
  }

  .differanceBox:nth-child(6) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/TKM_7367.webp);
    background-position: center 50%;
  }

  .differanceBox:nth-child(7) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/16.webp);
    background-position: center 50%;
  }

  .differanceBox:nth-child(8) {
    background: var(--mobileDifferanceLinear),
      url(../../assets/compressed/584.webp);
    background-position: center 60%;
  }

  .reviewsSlider {
    width: 470px;
    height: 350px;
  }

  .reviewCard {
    width: 295px;
    height: 350px;
  }

  .timeMapWrap {
    width: 500px;
  }
}

@media screen and (max-width: 535px) {
  .galleryDisplayImage {
    width: 295px;
    background-position: 50%;
  }

  .galleryMobileSlider {
    width: 295px;
  }

  .galleryMobileSlides {
    left: 85px;
  }

  #reviews-slidecontrol-left,
  #reviews-slidecontrol-right {
    transform: translateY(0%);
    top: 415px;
  }

  #reviews-slidecontrol-left {
    left: 20%;
  }

  #reviews-slidecontrol-right {
    right: 20%;
  }

  .reviewsSlider {
    width: 90vw;
    height: 500px;
  }

  .reviewsSlides {
    gap: 5px;
  }

  .reviewCard {
    width: 275px;
    height: 400px;
  }

  .timeMapWrap {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 15px;
    grid-template-areas:
      "oyunoynadik""hayvanbesledik""dramayaptik"
      "ingilizcekonustuk""yuzduk";
    width: 90vw;
  }
}

@media screen and (max-width: 460px) {
  .galleryDisplayImage {
    width: 275px;
  }

  .galleryMobileSlider {
    width: 275px;
  }
}