.navWrap {
  box-sizing: border-box;

  display: flex;
  max-width: 100vw;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f69200;
  padding: 0 20px;

  position: relative;

  border-bottom: 10px solid var(--white);

  z-index: 8;
}

.menuBg ~ .navWrap {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
}

.logoFloat {
  position: absolute;
  width: 200px;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  cursor: pointer;

  transition-duration: 0.3s;
}

.adButton {
  width: 200px;
  height: 62px;
  cursor: pointer;
}

.socialWrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.socialWrap > * {
  cursor: pointer;
}

.menuButton {
  display: none;
  cursor: pointer;
}

.mobileMenu {
  width: 100%;
  height: fit-content;
  background: var(--white);

  position: fixed;
  transform: translateX(-50%);
  top: 80px;
  left: 50%;
  border-radius: 0 0 10px 10px;

  padding: 15px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  z-index: 7;
  animation: slideinFromUp 0.4s ease-in;

  overflow-y: scroll;
  max-height: calc(100vh - 80px);
}

@keyframes slideinFromUp {
  0% {
    transform: translate(-50%, -700px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.mobileNavigationItem {
  width: 100%;
  height: 70px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 10px;
}

.navPlaceHolder {
  width: 100%;
  height: 80px;
  position: static;
}

.mobileNavigationItem > svg {
  width: 30px;
  height: 30px;
}

.mobileNavigationItem:is(.blue) {
  background-color: var(--blue);
  color: var(--white);
}

.mobileNavigationItem:is(.brand) {
  background-color: var(--brand);
  color: var(--white);
}

.mobileNavigationItem:is(.green) {
  background-color: var(--green);
  color: var(--white);
}

.mobileNavigationItem:is(.magenta) {
  background-color: var(--magenta);
  color: var(--white);
}

.mobileNavigationItem:is(.magenta) > svg {
  stroke: var(--white);
}

.mobileNavigationItem:is(.purple) {
  background-color: var(--purple);
  color: var(--white);
}

.mobileNavigationItem:is(.pink) {
  background-color: var(--pink);
  color: var(--white);
}

.mobileNavigationItem:is(.white) {
  background: url(../../assets/kampusPattern.png),
    linear-gradient(0deg, #ffffff, #ffffff);
  color: var(--blue);
  border: 4px solid var(--blue);
}

.menuBg {
  position: fixed;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  animation: dissolve 0.4s;
}

@keyframes dissolve {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .navWrap > a:nth-child(1) {
    display: none;
  }

  .socialWrap {
    display: none;
  }

  .logoFloat {
    position: static;
    width: 100px;
    transform: translateX(0);
  }

  .navWrap {
    padding: 0 20px 0 10px;
  }

  .menuButton {
    display: block;
    width: 25px;
  }
}
