.subPages_galeri {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 50px;
  width: 850px;
}

.subPages_galeri>* {
  position: relative;
}

.subPages_galeri>*>* {
  width: 250px;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: var(--white);
}

.subPages_galeri>*>*>img {
  height: 100%;
}

.imageDisplay {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: var(--black);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.imageDisplay>img {
  max-width: 100%;
  max-height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

@media screen and (max-width: 900px) {
  .subPages_galeri {
    width: 100%;
    justify-content: center;
  }
}