.notFound {
    padding: 20px 20px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    width: 400px;

    align-self: center;
    margin: auto;
    margin-top: 100px;

    box-shadow:
        15.8px 15.8px 12.5px rgba(0, 0, 0, 0.018),
        29.8px 29.8px 34.7px rgba(0, 0, 0, 0.013),
        44.4px 44.4px 83.5px rgba(0, 0, 0, 0.015),
        100px 100px 277px rgba(0, 0, 0, 0.09);

    box-sizing: border-box;
}

.notFound img {
    width: 100%;
    border-radius: 7px;
}

.notFound p {
    color: var(--blackText);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;

    text-align: center;
}

@media screen and (max-width: 500px) {
    .notFound {
        width: calc(100vw - 40px);
    }
}