#root {
  min-height: 100svh !important;
}
body {
  min-height: 100svh !important;
}

.birthdayWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  height: 100svh;
  z-index: 10000;
  background-color: #f4f4f0;
  overflow: hidden;

  display: grid;
  place-items: center;

  margin: 0;
  padding: 0;
}

.birthdayWrapper .videoWrapper video {
  width: 100%;
}

.birthdayWrapper .videoWrapper {
  width: 100%;
  margin: 0;
  height: min-content;
  position: relative;
  z-index: 100000;
}

.birthdayWrapper .videoWrapper .textFlow {
  position: absolute;
  z-index: 100000;
  transform: translate(-50%, -50%);
  right: 28%;
  top: 45%;
  text-align: center;

  width: 0;
  height: 0;
  overflow: visible;
}

@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap");

.birthdayWrapper .videoWrapper .textFlow p {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  color: #005859;
  font-size: 50px;
  font-family: "Kalam", sans-serif;
  font-weight: 300;
  line-height: 60px;
  width: 500px;
}

.birthdayCard {
  background-color: var(--white);
  padding: 20px 40px;
  padding-top: 60px;
  padding-inline: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 40px;
  place-items: center;
  position: relative;

  width: 350px;
}

.birthdayCard img {
  width: 150px;
  transform: translateY(-50%);
  position: absolute;
  top: 0;
}

.birthdayCard h1 {
  font-size: 25px;
  color: var(--blackText);
}

.birthdayCard .birthdayButton {
  border: none;
  background-color: var(--brand);
  color: var(--white);
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 7px;
  cursor: pointer;
  width: 100%;
}

.replayButton {
  padding-left: 10px;
  padding-right: 25px;
  padding-block: 10px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #005859;
  border-radius: 100px;
  cursor: pointer;

  position: absolute;
  bottom: 20px;
  right: 20px;

  transform: translate(0%, 0%);

  z-index: 10000000;

  transition-duration: 1s;
}

.replayButton img {
  width: 36px;
  aspect-ratio: 1/1;
}

.replayButton p {
  color: #a7f3f4;
  font-size: 16px;
}

@media screen and (max-width: 935px) {
  .birthdayWrapper .videoWrapper .textFlow p {
    font-size: 36px;
  }
}

@media screen and (max-width: 850px) {
  .birthdayWrapper .videoWrapper .textFlow p {
    font-size: 30px;
    width: 300px;
  }
  .replayButton img {
    width: 25px;
  }
  .replayButton p {
    font-size: 16px;
  }
}

@media screen and (max-width: 675px) {
  .birthdayCard {
    width: 90%;
    box-sizing: border-box;
    gap: 30px;
  }

  .birthdayCard h1 {
    font-size: 20px;
  }
  .birthdayCard .birthdayButton {
    font-size: 17px;
  }

  .videoWrapper {
    height: 100svh !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  video {
    --mask-gradient: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 98%
    );
    -webkit-mask-image: var(--mask-gradient);
    mask-image: var(--mask-gradient);
  }

  .replayButton {
    gap: 10px;
    bottom: 20px;
  }
  /* _::-webkit-full-page-media,
  _:future,
  :root .replayButton {
    bottom: 40px;
  } */
  .replayButton img {
    width: 25px;
  }
  .replayButton p {
    font-size: 16px;
  }

  .birthdayWrapper .videoWrapper .textFlow {
    right: 50%;
    top: 35%;
  }
  .birthdayWrapper .videoWrapper .textFlow p {
    font-size: 30px;
    width: 90vw;
    line-height: 40px;
  }
}

@media screen and (min-aspect-ratio: 16/9) {
  .birthdayWrapper .videoWrapper {
    height: 100svh;
  }
}

@media screen and (max-aspect-ratio: 53/101) {
  .birthdayWrapper .videoWrapper .textFlow {
    right: 50%;
    top: 10%;
  }
}
