.admin_form_wrap {
  position: relative;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  padding: 30px;
  border-radius: 10px;
  background: var(--white);
  margin-bottom: 80px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin_form_wrap > span {
  font-size: 13px;
  text-align: center;
}

.admin_gallery_radioWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.admin_gallery_radio {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.admin_inputWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.admin_inputWrap > input {
  background-color: var(--gray);
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
  padding: 0 0 0 10px;
}

.admin_inputWrap > textarea {
  background-color: var(--gray);
  font-family: Inter;
  border: none;
  border-radius: 5px;
  height: 150px;
  outline: none;
  padding: 10px 0 0 10px;
  resize: none;
}

.admin_inputWrap > textarea::-webkit-scrollbar {
  width: 15px;
  border-radius: 20px;
  cursor: pointer;
}

.admin_inputWrap > textarea::-webkit-scrollbar-track {
  border-radius: 0;
  cursor: pointer;
}
.admin_inputWrap > textarea::-webkit-scrollbar-thumb {
  width: 15px;
  background: rgba(0, 0, 0, 0.3);
  border: 5px solid var(--gray);
  border-radius: 15px;
  cursor: pointer;
}
.admin_inputWrap > textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.admin_inputWrap > label {
  color: var(--black);
  font-size: 14px;
}

.admin_image_dragDrop {
  width: stretch;
  padding: 30px;
  border: 2px dashed var(--blue);
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(0, 128, 255, 0.1);
  font-size: 14px;
  position: relative;
}

.admin_image_dragDrop > span {
  padding: 5px 10px;
  background: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--white);
}

.admin_image_dragDrop > input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.admin_save_button {
  height: 35px;
  border: none;
  outline: none;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 5px;
}

.data_table {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  background-color: var(--white);
  border-spacing: 10px;
  padding: 20px;
  width: 600px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 30px;
}

.data_table tr:nth-child(1n + 1) td:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.data_table td button {
  color: var(--red);
  width: 125px;
  height: 22px;
  background: rgb(255 0 0 / 10%);
  border: 2px solid var(--red);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.data_table th select {
  width: 125px;
  height: 22px;
  background: var(--blue);
  border: none;
  color: var(--white);
  padding: 0 0 0 10px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.data_table tr td > svg {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.data_table tr svg > g > * {
  fill: var(--blue) !important;
}

.data_table tr p {
  max-width: 180px;
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.data_table tr > *:nth-child(3) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
