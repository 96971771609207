.actionWrap {
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: fit-content;
}

.adminAction {
  width: 385px;
  border-radius: 10px;
  background: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  height: 87px;
  padding: 25px 50px;
  box-sizing: border-box;
  text-decoration: none;
}

.adminAction svg {
  width: 37px;
  height: 37px;
}

.adminAction:nth-child(4n + 1) {
  background: var(--blue);
}

.adminAction:nth-child(4n + 2) {
  background: var(--brand);
}

.adminAction:nth-child(4n + 3) {
  background: var(--green);
}

.adminAction:nth-child(4n + 4) {
  background: var(--magenta);
}

.adminAction:nth-child(4) svg {
  width: 50px;
  height: 50px;
}

.imageIconAdmin > g > * {
  fill: var(--white) !important;
}

.adminAction:nth-child(1) > svg {
  stroke: var(--white) !important;
}

.adminAction:nth-child(3) > svg > path {
  fill: var(--white) !important;
}

.adminAction:nth-child(4) > svg > path {
  stroke: var(--white) !important;
}

.adminAction h1 {
  font-size: 20px;
  font-weight: 550;
  text-align: center;
  color: var(--white);
}
