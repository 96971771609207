.defaultPageWrap {
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding-inline: 20px;
}

.pageNavigationWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.pageNavigationItem {
  padding: 5px 10px;
  border-radius: 3.5px;
  text-decoration: none;
  font-size: 15px;
  flex: 1 1 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pageNavigationWrap.blue .pageNavigationItem {
  background-color: rgba(0, 128, 255, 0.1);
  color: var(--blue);
}
.pageNavigationWrap.blue .pageNavigationItem.active {
  background-color: var(--blue);
  color: var(--white);
}

.pageNavigationWrap.brand .pageNavigationItem {
  background-color: rgba(246, 146, 0, 0.1);
  color: var(--brand);
}
.pageNavigationWrap.brand .pageNavigationItem.active {
  background-color: var(--brand);
  color: var(--white);
}

.pageNavigationWrap.green .pageNavigationItem {
  background-color: rgba(0, 229, 0, 0.1);
  color: var(--green);
}
.pageNavigationWrap.green .pageNavigationItem.active {
  background-color: var(--green);
  color: var(--white);
}

.pageNavigationWrap.purple .pageNavigationItem {
  background-color: #cf00d312;
  color: var(--purple);
}
.pageNavigationWrap.purple .pageNavigationItem.active {
  background-color: var(--purple);
  color: var(--white);
}

.mobilePageNavigation {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  transition-duration: 0.4s;
  top: 80px;
  z-index: 6;
}

.mobilePageNavigationItemsWrap {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 2px solid var(--white);
  width: 100%;
}

.mobilePageNavigationItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
  width: 100%;
  color: var(--white);
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
}

.mobilePageNavigation.blue .mobilePageNavigationItem {
  background-color: var(--blue);
}
.mobilePageNavigation.blue .mobilePageNavigationItem.active {
  background-color: var(--white);
  opacity: 1;
  color: var(--blue);
}

.mobilePageNavigation.brand .mobilePageNavigationItem {
  background-color: var(--brand);
}
.mobilePageNavigation.brand .mobilePageNavigationItem.active {
  background-color: var(--white);
  opacity: 1;
  color: var(--brand);
}

.mobilePageNavigation.green .mobilePageNavigationItem {
  background-color: var(--green);
}
.mobilePageNavigation.green .mobilePageNavigationItem.active {
  background-color: var(--white);
  opacity: 1;
  color: var(--green);
}

.mobilePageNavigation.purple .mobilePageNavigationItem {
  background-color: var(--purple);
}
.mobilePageNavigation.purple .mobilePageNavigationItem.active {
  background-color: var(--white);
  opacity: 1;
  color: var(--purple);
}

.mobilePageNavigationToggleButton {
  font-size: 14px;
  width: 100vw;
  border: none;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  box-sizing: border-box;
}

.mobilePageNavigation.blue .mobilePageNavigationToggleButton {
  background: var(--blue);
}
.mobilePageNavigation.brand .mobilePageNavigationToggleButton {
  background: var(--brand);
}
.mobilePageNavigation.green .mobilePageNavigationToggleButton {
  background: var(--green);
}
.mobilePageNavigation.purple .mobilePageNavigationToggleButton {
  background: var(--purple);
}

.mobilePageNavigationToggleButton > svg {
  transform: rotate(0deg);
  transition-duration: 0.4s;
}

.mobilePageNavigationToggleButton:is(.active) > svg {
  transform: rotate(180deg);
}

@media screen and (max-width: 1000px) {
  .defaultPageWrap {
    margin-top: 20px;
  }
}

@media screen and (max-width: 612px) {
  .defaultPageWrap {
    margin-top: 50px;
  }
  .mobilePageNavigation {
    display: flex;
  }
  .pageNavigationWrap {
    display: none;
  }
}
