.input_text > input {
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 46px;
  font-size: 18px;
  padding-left: 20px;

  border-radius: 10px;

  border: none;

  background-color: var(--background);

  color: var(--form-text-color);

  &::-webkit-input-placeholder {
    font-size: 18px;
    color: var(--placeholder-color);
  }
  &:-moz-placeholder {
    font-size: 18px;
    color: var(--placeholder-color);
  }
  &::-moz-placeholder {
    font-size: 18px;
    color: var(--placeholder-color);
  }
  &:-ms-input-placeholder {
    font-size: 18px;
    color: var(--placeholder-color);
  }
}

.input_text > input[data-error="true"] {
  --error-red: rgb(207, 12, 12);
  --error-red-ph: rgba(207, 12, 12, 0.726);
  background-color: #f0e0e0 !important;

  color: var(--error-red) !important;

  &::-webkit-input-placeholder {
    color: var(--error-red-ph) !important;
  }
  &:-moz-placeholder {
    color: var(--error-red-ph) !important;
  }
  &::-moz-placeholder {
    color: var(--error-red-ph) !important;
  }
  &:-ms-input-placeholder {
    color: var(--error-red-ph) !important;
  }
}

.input_text > input:focus {
  outline-width: 0;
}

.input_text {
  --background: #eceff0;
  --placeholder-color: #989baa;
  --label-color: #797b86;
  position: relative;
}

.input_text > label {
  position: absolute;
  top: -10px;
  left: 20px;

  font-size: 10px;

  background-color: var(--white);
  padding: 4px;
  border-radius: 3px;

  animation-name: labelPop;
  animation-duration: 0.3s;

  color: var(--label-color);
}

@keyframes labelPop {
  from {
    font-size: 13px;
    top: 5px;
    background-color: var(--background);
  }
  to {
    font-size: 10px;
    top: -10px;
    background-color: var(--white);
  }
}

@media screen and (max-width: 726px) {
  .input_text > input {
    height: 40px;
    font-size: 16px;
    padding-left: 14px;

    &::-webkit-input-placeholder {
      font-size: 16px;
      color: var(--placeholder-color);
    }
    &:-moz-placeholder {
      font-size: 16px;
      color: var(--placeholder-color);
    }
    &::-moz-placeholder {
      font-size: 16px;
      color: var(--placeholder-color);
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      color: var(--placeholder-color);
    }
  }

  .input_text > label {
    position: absolute;
    top: -10px;
    left: 14px;
  }
}
