.formWrapper {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  margin-top: 100px;
  align-self: center;
}

.lockedFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.lockedField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #a1bbd4;
  border-radius: 5px;
  color: var(--white);
  padding: 15px 20px;
}

.fieldWrap {
  height: 75px;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23F69200FF' stroke-width='7' stroke-dasharray='15' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
}

.fieldsInnerWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fieldsWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fieldWrap input {
  outline: none;
  width: -webkit-fill-available;
  height: 45px;
  border: none;
  background: var(--white);
  padding: 0 20px;
  border-radius: 5px;
  color: var(--blackText);
  font-size: 16px;
}

.fieldActionButtonsWrap {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100px;
  position: relative;
  top: -37px;
  right: -413px;
  justify-content: right;
  align-items: center;
}

.fieldActionButtonsWrap button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background: var(--brand);
  color: var(--white);
  outline: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldActionButtonsWrap div {
  width: 30px;
  height: 30px;
  background: var(--blue);
  border-radius: 5px;
  color: var(--white);

  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldActionButtonsWrap svg {
  fill: var(--white);
  width: 17px;
  height: 17px;
}

.asterisk svg {
  width: 12px;
  height: 12px;
}

.checkbox {
  fill: none !important;
}

.green:is(button) {
  background: var(--green) !important;
}

.red {
  background: var(--red) !important;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.actionButtons button {
  color: var(--white);
  background-color: var(--brand);
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
