.input_checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 14px;
  gap: 10px;

  position: relative;

  p {
    font-size: 18px;
    color: #3f3e3e;
  }

  a {
    text-decoration: underline;
    color: var(--blue);
  }

  span {
    color: rgb(207, 12, 12);
  }
}

.input_checkbox[data-error="true"] label {
  --error-red: rgb(207, 12, 12);
  color: var(--error-red) !important;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--red);
    left: -30px;
    transform: translateY(-50%);
    top: 50%;
  }
}
.input_checkbox > input[type="checkbox"] {
  --size: 20px;
  min-width: var(--size);
  min-height: var(--size);
  color: var(--blue);

  position: relative;

  border-radius: 2px;
  /* overflow: hidden; */

  &:before {
    position: absolute;
    display: block;
    min-width: 100%;
    min-height: 100%;
    content: "";
    background: #fff;
    z-index: 1;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: "";
    border: 2px solid var(--blue);
    background-color: var(--white);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    z-index: 2;
  }

  &:checked:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAKCAYAAACE2W/HAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABhSURBVHgBnc6BDQARDAXQuw06wo1wmxrBKEYwghGMUEUlJMSnSUn0v+j7HBQzf3K5E1OQdOBa4QqVn+UgXQFBPufbID9G6R9GOjTDCgjqQq7DBCGFpIEW3KPFahiaYLtDCbsh2tSnSoG2AAAAAElFTkSuQmCC");
    background-size: 8px;
    background-color: var(--blue);
  }
}

.checkbox_modal {
  position: fixed;

  transform: translate(-50%, -50%);

  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 10px;
  border-radius: 20px;

  width: calc(100svw - 40px);
  max-width: 500px;

  &::backdrop {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 10, 56, 0.1);
  }
}

.checkbox_modal:not([open]) {
  display: none;
}

.dialogPage {
  height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 15px;
    border-radius: 20px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: rgba(0, 0, 0, 0.3);
    border: 5px solid var(--white);
    border-radius: 15px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.dialogButtonWrap {
  width: 100%;
  height: 40px;
  display: grid;
  gap: 10px;

  z-index: 10;

  & > * {
    width: 100%;
    height: 100%;
  }

  & > button {
    border: none;
    border-radius: 10px;

    color: var(--black);
    background-color: rgb(207, 212, 223);

    cursor: pointer;

    grid-column-start: 1;
    grid-column-end: 1;

    &:nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 4;
      color: white;
      background-color: var(--blue);
    }

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.dialogInfo {
  position: absolute;

  opacity: 0;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;

  z-index: 0;

  width: max-content;

  background-color: var(--white);
  padding: 10px 20px;

  border-radius: 30px;

  border: 2px solid var(--blue);

  transform: translateX(-50%);

  bottom: 10%;
  left: 50%;

  animation-name: slideUp;
  animation-delay: 0.2s;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &[data-show="false"] {
    animation-fill-mode: backwards;
    animation-duration: 0.2s;
    animation-delay: 0;
    animation-name: slideDownDisappear;
  }

  & > svg,
  & > svg * {
    fill: var(--blue) !important;
  }
}

@keyframes slideUp {
  from {
    transform: translateX(-50%) translateY(40%);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0%);
    opacity: 1;
  }
}

@keyframes slideDownDisappear {
  from {
    transform: translateX(-50%) translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-50%) translateY(40%);
    opacity: 0;
  }
}

@media screen and (max-width: 726px) {
  .dialogButtonWrap {
    button {
      font-size: 14px;
    }
  }

  .dialogInfo {
    font-size: 15px;
  }

  .input_checkbox[data-error="true"] p {
    &::before {
      left: -20px;
    }
  }
}
