footer {
    background-color: #372c88;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 0;
    z-index: 3;
    font-size: .875rem;

    position: relative;
    bottom: 0;
}

footer>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding-block: 30px;
}

.line {
    width: 100%;
    height: 1px;
    background: var(--white);
    position: relative;
    opacity: 0.2;
}

.line:before {
    content: "";
    position: absolute;
    width: 10px;

}

footer>div>ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 27px;
    row-gap: 15px;
    padding: 0;

    align-items: center;
    justify-content: center;

    color: var(--white);
}

footer>div>ul>li:nth-child(1) {
    list-style: none;
}

footer>div>ul>li>a {
    color: var(--white);
    text-decoration: none;

    opacity: 0.8;
}

footer>div>ul>li>a:hover {
    opacity: 1;
}

@media screen and (max-width: 700px) {
    footer>div>ul {
        padding-inline: 30px;
    }
}

@media screen and (max-width: 410px) {
    footer>div>ul>li:nth-child(4) {
        list-style: none;
    }
}

@media screen and (max-width: 325px) {
    footer>div>ul>li:nth-child(3) {
        list-style: none;
    }

    footer>div>ul>li:nth-child(4) {
        list-style: disc;
    }
}

@media screen and (max-width: 315px) {
    footer {
        font-size: .750rem;
    }

    footer>div>ul {
        flex-direction: column;
    }

    footer>div>ul>li:nth-child(1),
    footer>div>ul>li:nth-child(3) {
        list-style: disc;
    }
}