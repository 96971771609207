.input_date {
  --background: #eceff0;
  --text-color: #989baa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;

  gap: 10px;

  position: relative;

  p {
    font-size: 18px;
    color: var(--form-text-color);
  }
}

.input_date[data-error="true"] p {
  --error-red: rgb(207, 12, 12);
  color: var(--error-red) !important;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--red);
    left: -30px;
    transform: translateY(-50%);
    top: 50%;
  }
}

.input_date input {
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  &::-ms-expand {
    display: none;
  }
}

.input_date *::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.inputPlaceholder > input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  background: none;

  text-align: left;

  font-size: 14px;

  padding-left: 13px;
  padding-right: 8px;
  color: var(--form-text-color);

  &::before {
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(../../../../assets/icons/dateFilledGray.svg);
    background-size: contain;
    position: absolute;

    right: 8px;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }
}

.inputPlaceholder {
  width: 138px;
  height: 36px;
  border-radius: 5px;

  overflow: hidden;

  background-color: var(--background);

  cursor: pointer;
}

@media screen and (max-width: 726px) {
  .input_date[data-error="true"] p {
    &::before {
      left: -20px;
    }
  }
}
