.loginForm {
    margin: auto;
    margin-top: 100px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: left;
    justify-content: center;
    width: 250px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;
}

.loginForm label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.adminLogo {
    align-self: center;
    width: 225px;
    margin: auto;
    margin-bottom: 15px;
}

.loginForm input {
    border-radius: 5px;
    background: var(--gray);
    outline: none;
    border: none;
    padding: 10px;
}

.loginForm span {
    color: var(--red);
    font-size: 13px;
    text-transform: lowercase;
}

.loginForm button {
    color: var(--white);
    background: var(--brand);
    border-radius: 5px;
    padding: 15px 0 15px 0;
    outline: none;
    border: none;
    margin-top: 15px;

}