.landingHeader {
  width: stretch;
  height: 75vh;
  background: url("../../assets/compressed/girisfoto.webp");
  background-size: cover;

  display: flex;
  align-items: center;
  padding-left: calc((100vw - 1161.61px) / 2);

  transition-duration: 0.3s;
}

.headerWrap {
  width: 417px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 15px;

  text-align: left;
}

.headerWrap > h1 {
  font-size: 40px;
  font-weight: bold;
}

.button {
  height: fit-content;
  width: fit-content;
  padding: 13px 26px;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  font-size: 16px;
}

.but-4324 {
  width: 100%;
}

.button.bold {
  font-weight: 700;
}

.button.whiteBack {
  background: var(--white);
}

.button.blueFront {
  color: var(--blue);
}

/* POP UP */
.homePopUp {
  /* CARD */
  width: 360px;
  padding: 10px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: -10px 5px 30px rgb(61 95 143 / 40%);

  /* FLEX */
  display: flex;
  flex-direction: column;
  gap: 15px;

  /* TEXT */
  font-size: 20px;

  /* POSITION */
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 6;

  /* ANIMATION */
  animation: 0.2s dissolve linear;
}

.homePopUp > span {
  font-size: 1em;
  font-weight: 600;
}

.homePopUp > img {
  width: 100%;
  border-radius: 5px;
}

.homePopUp > i {
  /* POSITION */
  position: absolute;
  top: 15px;
  right: 15px;

  /* BUTTON */
  cursor: pointer;

  /* BALL */
  border-radius: 100%;
  background: var(--white);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 11;
}

.homePopUp > i > svg {
  width: 12px;
  height: 12px;
}

.homePopUp > a:not(.coverbutton) {
  padding-block: 10px;
  width: 100%;
  background: var(--blue);
  border-radius: 5px;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.homePopUp > .mobileDetails {
  display: none;
}

/* AD SPACE */
.adSpace {
  width: 100%;
  height: 130px;
  background: var(--white);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adSpace a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adSpace img {
  width: 100%;
  height: 100%;
}

/* INFO POP UP */
.infoPopUp[open] {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: var(--white);
  padding: 5px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: calc(100svh / 2);
  left: 50%;

  border: none;

  z-index: 10000000;

  box-sizing: border-box;

  margin: 0;

  max-width: calc(100svw - 30px);
}

.infoPopUp[open].asp_1-1 {
  aspect-ratio: 1/1;
  width: 500px;
}

.infoPopUp[open].asp_16-9 {
  aspect-ratio: 16/9;
  width: 700px;
}

.infoPopUp form {
  position: absolute;
  top: 10px;
  right: 10px;

  display: grid;
  justify-content: flex-end;

  width: calc(100% - 10px);
}
.infoPopUp button {
  aspect-ratio: 1/1;
  width: 25px;
  border: none;
  border-radius: 100%;
  background-color: var(--white);
  cursor: pointer;

  transition-duration: 0.2s;

  display: grid;
  place-content: center;

  position: relative;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: var(--gray);
  }
}

.infoPopUp button::after {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}

.infoPopUp a {
  width: 100%;
  height: 100%;
}

.infoPopUp picture {
  border-radius: 15px;
  overflow: hidden;
}

.infoPopUp picture img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

dialog[open]::backdrop {
  background: var(--black);
  opacity: 0.7;
}

.pulse {
  animation: pulseScale 1s;
  animation-delay: 0.5s;
}

@keyframes pulseScale {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1290px) {
  .landingHeader {
    padding-left: calc((100vw - 923px) / 2);
  }
}

@media screen and (max-width: 1000px) {
  .landingHeader {
    padding-left: 0;
    justify-content: center;
  }

  .button {
    width: stretch;
  }

  .homePopUp {
    top: calc(80px + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - 20px);
    box-sizing: border-box;
    height: fit-content;
    flex-direction: row;
    position: absolute;

    align-items: center;
  }

  .homePopUp > .mobileDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: center;
  }

  .homePopUp > .mobileDetails > span {
    font-size: 0.8em;
    font-weight: 600;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    width: calc(100% - 35px);
  }

  .homePopUp > .coverbutton {
    position: absolute;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    opacity: 0;
    cursor: pointer;
  }

  .homePopUp > .mobileDetails > a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    color: var(--white);
    background: var(--blue);
    border-radius: 5px;
    padding-block: 5px;
    width: stretch;
  }

  .homePopUp > a:not(.coverbutton) {
    display: none;
  }

  .homePopUp > img {
    width: 120px;
  }

  .homePopUp > span {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .headerWrap > h1 {
    font-size: 25px;
  }

  .headerWrap {
    width: stretch;
    padding: 20px;
    gap: 20px;
    padding-bottom: 40px;
  }

  .button {
    width: stretch;
  }

  .landingHeader {
    height: 75vh;
    align-items: flex-end;
  }

  .infoPopUp.asp_1-1 {
    width: 400px !important;
  }

  .infoPopUp.asp_16-9 {
    width: 325px !important;
    aspect-ratio: 9/16 !important;
  }

  .infoPopUp button {
    width: 28px;
  }
}

@keyframes dissolve {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
